<style lang="scss">
.themenschwerpunkte {
  [class*='col-'] {
  }
  .themen-box {
    margin: 50px 0;
    .col-lg-3 {
      margin-bottom: 30px;
      .bild-box {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 300px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: rgba($color: #000000, $alpha: 0.5);
          -webkit-transition: all 500ms ease;
          -moz-transition: all 500ms ease;
          -ms-transition: all 500ms ease;
          -o-transition: all 500ms ease;
          transition: all 500ms ease;
        }
        .txt {
          width: 100%;
          position: absolute;
          text-align: center;
          left: 0;
          top: 50%;
          z-index: 2;
          color: #fff;
          font-size: 150%;
          height: 40px;
          margin-top: -20px;
        }
        &:hover {
          &:after {
            background-color: rgba($color: #000000, $alpha: 0);
          }
          .txt {
            opacity: 0;
          }
        }
      }
    }
  }
  .empfehlungen {
    margin-top: 40px;
    margin-bottom: 40px;
    background: $blue-darker;
    padding: 30px 20px;
    color: #fff;
    .intro {
      text-align: center;
      h2 {
        margin-bottom: 0px;
        margin-top: 0 !important;
        padding-top: 0;
        font-weight: 500;
        &:after {
          margin: 10px auto !important;
        }
      }
      p {
        margin-bottom: 30px;
      }
    }
    .col-lg-2 {
      justify-content: center;
      align-self: flex-start;
      position: relative;

      .icon-box {
        display: block;
        width: 100%;
        img {
          max-width: 100px;
          display: block;
          margin: 0 auto;
        }
      }
    }

    .col-lg-6 {
      h3 {
        margin-bottom: 15px;
      }
      span {
        display: inline-block;
        padding: 15px 25px;
        background: #eee;
        font-size: 130%;
        color: $blue-darker;
      }
      .txt {
        padding: 15px;
        border-left: 3px solid #eee;
      }
    }
  }
  .text-center {
    text-align: center;

    display: block;
  }
}
</style>

<template>
  <div class="content themenschwerpunkte container">
    <h1>Themenschwerpunkte</h1>
    <p class="intro-txt"><strong>Sie suchen nach dem passenden Themenumfeld für Ihre Werbebotschaft, um diese zielgruppengenau auszuspielen? Kein Problem!</strong></p>
    <p>Themenschwerpunkte der Kleinen Zeitung widmen sich unterschiedlichsten Bereichen und orientieren sich stark an den Interessen und Bedürfnissen unserer User und Leser.</p>
    <p>Ob als abwechslungsreiche Beilage bzw. Bestandteil der Zeitung und/oder als interessanter Content auf kleinezeitung.at – Themenschwerpunkte sind bei den Lesern und Usern der Kleinen Zeitung besonders beliebt.</p>

    <div class="themen-box">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-bauen.jpg')})` }">
            <div class="txt">Bauen &amp; Wohnen</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-bildung.jpg')})` }">
            <div class="txt">Bildung &amp; Karriere</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-gesundheit.jpg')})` }">
            <div class="txt">Gesundheit &amp; Leben</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-kultur.jpg')})` }">
            <div class="txt">Kultur</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-lifestyle.jpg')})` }">
            <div class="txt">Lifestyle</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-mobilitaet.jpg')})` }">
            <div class="txt">Mobilität</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-tourismus.jpg')})` }">
            <div class="txt">Tourismus &amp; Reisen</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-wirtschaft.jpg')})` }">
            <div class="txt">Wirtschaft</div>
          </div>
        </div>
      </div>
    </div>
    <h2>Individuelle Werbelösungen: Der Mix macht’s!</h2>
    <p>
      Je nach Werbeziel empfehlen wir Ihnen eine Kombination aus Print und Digital, um Ihre Zielgruppe crossmedial zu erreichen und Streuverluste zu minimieren. Die Tarife richten sich dabei nach den allgemein gültigen Tarifen für das <router-link :to="{ name: 'Basisplatzierung' }" class="content-link">Hauptblatt - Basisplatzierung</router-link> bzw. für die jeweiligen
      <router-link :to="{ name: 'Programmatic Advertising' }" class="content-link">digitalen Werbeformen.</router-link>
    </p>

    <div class="row empfehlungen">
      <div class="col-lg-12">
        <div class="intro">
          <h2>Ihr Werbeziel – unsere Empfehlung.</h2>
          <p>Sie sind noch unschlüssig, mit welchen Werbemitteln Sie Ihre Werbeziele am besten erreichen? Hier eine Empfehlung für Sie!</p>
        </div>
      </div>
      <!--<div class="col-lg-2 col-md-12">
        <div class="icon-box"><img src="@/assets/img/icons/paket-kombination.svg" alt="Paket Kombination" class="img-fluid" /></div>
      </div>-->
      <div class="col-lg-6">
        <span>1</span>
        <div class="txt">
          <h3>Werbeziel Absatz steigern &amp; Kunden gewinnen</h3>
          <p>Werbeempfehlung: klassisches Print-Inserat im jeweiligen Themenschwerpunkt + Programmatische Kampagne auf kleinezeitung.at</p>
        </div>
      </div>
      <div class="col-lg-6">
        <span>2</span>
        <div class="txt">
          <h3>Werbeziel Markenstärkung &amp; Image</h3>
          <p>Werbeempfehlung: PR-Artikel im jeweiligen Themenschwerpunkt + Advertorial inkl. Content-Teaser auf kleinezeitung.at</p>
        </div>
      </div>
    </div>
    <h2>Umsetzungsbeispiele</h2>
    <div class="row umsetzungs-bsp justify-content-center">
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/sonderprodukte/Ausgabe-Magazin-Kostprobe-2020.pdf" target="_blank">
          <img src="@/assets/img/img-content/sonderprodukte/ausgabe-kostprobe.png" alt="Kostprobe" class="img-fluid" />
        </a>
      </div>
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/sonderprodukte/Ausgabe-Lebenslust-Sept-2020.pdf" target="_blank">
          <img src="@/assets/img/img-content/sonderprodukte/ausgabe-lebenslust.png" alt="Lebenslust" class="img-fluid" />
        </a>
      </div>
    </div>
    <div class="row umsetzungs-bsp justify-content-center">
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/sonderprodukte/Ausgabe-Themenmagazin-Mobilitaet.pdf" target="_blank">
          <img src="@/assets/img/img-content/sonderprodukte/ausgabe-mobilitaet.png" alt="Möbilität" class="img-fluid" />
        </a>
      </div>
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/sonderprodukte/Ausgabe-Reisezeit-Sept2020.pdf" target="_blank">
          <img src="@/assets/img/img-content/sonderprodukte/ausgabe-reisezeit.png" alt="Reisezeit" class="img-fluid" />
        </a>
      </div>
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/sonderprodukte/Ausgabe-Magazin-Lebensstil-April-2020.pdf" target="_blank">
          <img src="@/assets/img/img-content/sonderprodukte/ausgabe-lebensstil.png" alt="Lebensstil" class="img-fluid" />
        </a>
      </div>
    </div>

    <div class="row umsetzungs-bsp justify-content-center">
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/sonderprodukte/Ausgabe-IDA-Magazin-STMK.pdf" target="_blank">
          <img src="@/assets/img/img-content/sonderprodukte/ausgabe-ida.png" alt="IDA Magazin" class="img-fluid" />
          <small class="text-center">Ausgabe Steiermark</small>
        </a>
      </div>
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/sonderprodukte/Ausgabe-IDA-Magazin-KTN.pdf" target="_blank">
          <img src="@/assets/img/img-content/sonderprodukte/ausgabe-ida.png" alt="IDA Magazin" class="img-fluid" />
          <small class="text-center">Ausgabe Kärnten</small>
        </a>
      </div>
    </div>
    <div class="download-pdf">
      <div class="line"><span>Download</span></div>
      <a href="../../pdf/sonderprodukte/Spezifikation-Themenschwerpunkte-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Spezifikationen Themenschwerpunkte
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>87 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
    <Modal></Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Modal from '@/components/util/Modal.vue';

export default defineComponent({
  name: 'Themenschwerpunkte',
  components: {
    Modal,
  },
});
</script>
